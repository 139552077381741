import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faHeart, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import AboutMe from "./components/AboutMe";
import { SiFacebook, SiLinkedin, SiInstagram } from "react-icons/si";
import { FaHeart, FaEnvelope } from "react-icons/fa";

library.add(fab, faEnvelope, faHeart);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AboutMe />
        <div className="flex">
          <FaHeart /> Let's talk!
        </div>
        <div className="links">
          <a href="mailto:sigalitam@gmail.com">
            <FaEnvelope />
          </a>
          <a href="https://www.linkedin.com/in/sigalitam/" target="_blank">
            <SiLinkedin />
          </a>
          <a
            href="https://www.facebook.com/sigalit.amsalemyemini"
            target="_blank"
          >
            <SiFacebook />
          </a>
          <a href="https://www.instagram.com/sigalitam/" target="_blank">
            <SiInstagram />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
