import React from "react";

export default function AboutMe() {
  return (
    <>
      <div className="mainImageWrapper">
        <div className="mainImage">
          <img src={require("../assets/sigalit.jpg")} />
        </div>
      </div>
      <div className="mainTitle">
        <b>Hi!</b> I'm Sigalit
      </div>
      <div className="mainJobTitle">Front-end developer</div>
      <div className="description">
        I'm a front-end developer who is passionate about creating visually
        appealing and intuitive user interfaces. With over 12 years of
        experience and strong expertise in React, JavaScript, HTML, CSS, and
        other front-end technologies, I have a proven ability to deliver
        high-quality, responsive UX/UI designs.
      </div>
    </>
  );
}
